/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {

        /* -- initial scroll body */
        function initScrollBody() {
          var scrolltop = $(window).scrollTop();
          if (scrolltop > 0) {
            $("header").addClass("sticky");
          } else {
            $("header").removeClass("sticky");
          }
        }

        window.onscroll = function () {
          initScrollBody();
        };
        window.onload = function () {
          initScrollBody();
        };
        
        /*--- Link anchor --*/
        (function ($) {
          $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            $('html, body').animate({
              scrollTop: $($.attr(this, 'href')).offset().top
            }, 500);
          });
          


        })(jQuery);

        // detects if user is using the keyboard to navigate and if so, adds a class to body
        function handleFirstTab(e) {
          if (e.keyCode === 9 || e.keyCode === 32 || e.keyCode === 13 || e.keyCode === 37 || e.keyCode === 39) { // the "I am a keyboard user" key
            document.body.classList.add('user-is-tabbing');
            window.removeEventListener('keydown', handleFirstTab);
          }

        }

        window.addEventListener('keydown', handleFirstTab);
      },
      finalize: function () {


        var clean_language_menu =  function() {

          var is_america = window.localStorage.getItem( 'is_america' );

          if( is_america === 'true' ) {
            $( '.dropdown__subnav .wpml-ls-item-fr' ).remove();
          } else {
            $( '.dropdown__subnav .wpml-ls-item-fr-ca' ).remove();
          }

        };

        var redirect_geo_language = function( ) { 
          var target_language = window.localStorage.getItem( 'language' );
          var redirected = window.localStorage.getItem( 'redirected' );

          if( redirected !== 'true' ) {
            window.localStorage.setItem( 'redirected', 'true' );
            window.location.replace(georedirect.urls[target_language].url);
          }

        };

        var set_local_storage = function() {
          $.get( vtx_ajax.ajax_url, { 'action': 'get_user_language' }, function ( result ) {

            var tomorrow = new Date();

            tomorrow.setDate(tomorrow.getDate() + 1);

            window.localStorage.setItem( 'geo_expire', tomorrow.getTime() );
            window.localStorage.setItem( 'language', result.data.lang );
            window.localStorage.setItem( 'is_america', result.data.america );
            window.localStorage.setItem( 'is_spanish', result.data.spanish );
            redirect_geo_language();
            clean_language_menu();
          } );
        };

        $( '.dropdown__subnav .nav-item__link' ).click( function() {
          var switched_lang =  $( this ).find( 'span' ).attr( 'lang' );
          console.log( 'switched manually to ', switched_lang );
          window.localStorage.setItem( 'language', switched_lang );
        } );

        var spanish_check = window.localStorage.getItem( 'is_spanish' );

        var geo_expire = window.localStorage.getItem( 'geo_expire' );
        var now = new Date();

        if( geo_expire < now.getMilliseconds() || null === spanish_check ) {

          set_local_storage();

        } else {

          redirect_geo_language();

          clean_language_menu();

        }



        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
  $(window).bind("load", function () {
    window.dispatchEvent(new Event('resize'));
  });

})(jQuery); // Fully reference jQuery after this point.
