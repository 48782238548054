(function ($) {

    // functions 

    $(document).ready(function () {

        // document ready
        if ($('.accordion__toggle').length > 0) {

            $('.accordion__toggle').click(function () {
                var question = $(this).parents('.accordion__wrapper');
                var child = $(this).children('.accordion__icon__toggle');

                if (!question.hasClass('active')) {
                    question.addClass('active');

                    child.addClass('active');
                    question.find('.accordion__content').slideDown('fast');
                    question.find('.accordion__toggle').attr('aria-expanded', 'true');

                    //when very long accordeons are closing, brings back the user to right place
                    /*                   setTimeout(function(){  
                                          $('html, body').animate({
                                              scrollTop: question.offset().top - 200
                                          }, 1000); 
                                      }, 300); */

                    /* les autres questions */
                    $('.accordion__wrapper').not(question).each(function () {
                        $(this).removeClass('active');
                        $(this).find('.accordion__toggle').attr('aria-expanded', 'false');
                        $(this).find('.accordion__icon__toggle').removeClass('active');
                        $(this).find('.accordion__content').slideUp('fast');
                    });
                } else {
                    question.removeClass('active');
                    child.removeClass('active');
                    question.find('.accordion__content').slideUp('fast');
                }
            });
        }
    });
})(jQuery);

// accordion page contact
var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].onclick = function () {
        this.classList.toggle("active");
        this.nextElementSibling.classList.toggle("show");
    };
}

var acc = document.getElementsByClassName("title_category");
var i;

if (window.screen.width <= 600) {
    for (i = 0; i < acc.length; i++) {
        acc[i].onclick = function () {
            this.classList.toggle("active");
            this.nextElementSibling.classList.toggle("show");
        };
    }
}