(function ($) {

  /*-- Bloc slider home --*/
  $('.slides').flickity({
    // options
    cellAlign: 'left',
    prevNextButtons: false,
    adaptiveHeight: true,
    autoPlay: 6000,
    pauseAutoPlayOnHover: false,
  });

  /*-- Bloc Timeline content --*/
  $('.evolution-timeline-year').flickity({
    // options
    pageDots: false,
    prevNextButtons: true,
    autoPlay: 6000
  });

  /*-- Bloc Timeline year --*/
  $('.evolution-timeline-slider').flickity({
    // options
    asNavFor: '.evolution-timeline-year',
    //contain: true,
    prevNextButtons: false,
    pageDots: false,
    adaptiveHeight: true,
  });
  
  /*-- Bloc Carrousel --*/
  $('.carousel').flickity({
    // options
    wrapAround: true,
    prevNextButtons: true,
    adaptiveHeight: true,
    autoPlay: 4500,
    pauseAutoPlayOnHover: false,
    imagesLoaded: true,
    freeScroll: true
  });
  
    /*-- Bloc article --*/
    $('.articles_wrapper').flickity({
      // options
      wrapAround: true,
      prevNextButtons: true,
      pauseAutoPlayOnHover: false,
      freeScroll: false,
      autoPlay: 4500
    });

})(jQuery);

