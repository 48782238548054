(function ($) {
  // tabbed content
  // http://www.entheosweb.com/tutorials/css/tabs.asp
  $(".tab_content").hide();
  $(".tab_content:first").show();
  $('button.tab_title').first().addClass("active");

  /* if in tab mode */
  $("button.tab_title").click(function () {

    $(".tab_content").hide();
    var activeTab = $(this).attr("rel");
    $("#" + activeTab).fadeIn();

    if ($(window).width() > 1080) {
      $('html,body').animate({scrollTop: $('.block-tab').offset().top - 120},'slow');
    } else {
      $('html,body').animate({scrollTop: $("#" + activeTab).offset().top - 60},'slow');
    } 
     
    $("button.tab_title").removeClass("active");
    $(this).addClass("active");

  });
  
  // tabbed content second
  // http://www.entheosweb.com/tutorials/css/tabs.asp
  $(".tab_content_second").hide();
  $(".tab_content_second:first").show();
  $('button.tab_title_second').first().addClass("active");

  /* if in tab mode */
  $("button.tab_title_second").click(function () {

    $(".tab_content_second").hide();
    var activeTab = $(this).attr("relsecond");
    $("#" + activeTab).fadeIn();

    if ($(window).width() > 1080) {
      $('html,body').animate({scrollTop: $('.block-tab-second').offset().top - 193},'slow');
    } else {
      $('html,body').animate({scrollTop: $("#" + activeTab).offset().top - 60},'slow');
    } 
     
    $("button.tab_title_second").removeClass("active");
    $(this).addClass("active");

  });

})(jQuery);