(function ($) {
  $(document).ready(function () {
    if ($('.market-item').length > 0) {
      $('.market-item').on('mouseenter', function (e) {
        if ($(window).width() > 1080) {
          $(this).find('.market-content').stop().slideDown(400);
        }
      });

      $('.market-item').on('mouseleave', function (e) {
        if ($(window).width() > 1080) {
          $(this).find('.market-content').stop().slideUp(400);
        }
      });
    }
  });

  //anchor page
  $(function () {
    $('a[href*=\\#]:not([href=\\#])').on('click', function () {
      if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {

        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {

          if ($('.vtx-burger-container').is(":visible")) {
            $('.vtx-burger-container').trigger("click");
          }

          $('html,body').animate({
            scrollTop: target.offset().top - 110
          }, 1000);
          return false;
        }
      }


    });
    //Executed on page load with URL containing an anchor tag.
    if ($(location.href.split("#")[1])) {
      var target = $('#' + location.href.split("#")[1]);
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - 110
        }, 1000);
        return false;
      }
    }
  });

})(jQuery);


